import React from 'react'
import Helmet from 'react-helmet'
import Pricing from '../Pricing'
import PropTypes from 'prop-types'

import ReactSVG from 'react-svg'

const PricingPageTemplate = ({
  title,
  meta_title,
  meta_description,
  pricing,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>



    <section className="bg-primary-alt header-inner o-hidden">
      <div className="container">
        <div className="row my-3">
          <div className="col">
            {/*<nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Pages</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Pricing Plans</li>
              </ol>
            </nav>*/}
          </div>
        </div>
        <div className="row py-6 text-center justify-content-center align-items-center">
          <div className="col-xl-8 col-lg-10">
            <h1 className="display-4">Pays for itself with the results you'll get from having an aligned team</h1>
            <p className="lead mb-0">Try OKR Tracker free for 14 days, no credit card required. Pick a plan later.</p>
            <br/>
            <button className="btn btn-lg btn-primary" type="submit">Start your free trail</button>
          </div>
        </div>
      </div>
      <div className="decoration-wrapper d-none d-md-block">
        <div className="decoration left middle-y scale-2" data-jarallax-element="100">
          {/*<img className="bg-primary" src={'img/decorations/deco-dots-4.svg'} alt="deco-dots-4 decoration" data-inject-svg />*/}
          <ReactSVG
            src={'img/decorations/deco-dots-4.svg'} 
            beforeInjection={svg => {
              svg.classList.add('bg-primary')
            }}
          />
        </div>
        <div className="decoration bottom right scale-2" data-jarallax-element="100 100">
          {/*<img className="bg-primary-2" src={'img/decorations/deco-blob-6.svg'} alt="deco-blob-6 decoration" data-inject-svg />*/}
          <ReactSVG
            src={'img/decorations/deco-blob-6.svg'} 
            beforeInjection={svg => {
              svg.classList.add('bg-primary-2')
            }}
          />
        </div>
      </div>
      <div className="divider">
        {/*<img src={'img/dividers/divider-1.svg'} alt="graphical divider" data-inject-svg />*/}
        <ReactSVG
          src={'img/dividers/divider-1.svg'} 
        />
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body align-items-center ">
              <div className="pt-md-2">
                <h4>Startup</h4>
              </div>
              <div className="d-flex align-items-start pb-md-2">
                <span className="h3">$</span>
                <span className="display-4">9</span>
              </div>
              <span className="text-small text-muted">Per user, per month</span>
              <ul className="text-center list-unstyled my-2 my-md-4">
                <li className="py-1">
                  <span>Up to 5 users</span>
                </li>
                <li className="py-1">
                  <span>Goals (OKRs)</span>
                </li>
                <li className="py-1">
                  <span>Weekly Reflections</span>
                </li>
                <li className="py-1">
                  <span>Monthly Scoring</span>
                </li>
                <li className="py-1">
                  <span>Quarterly Reviews</span>
                </li>
                <li className="py-1">
                  <span className="text-muted text-strikethrough">Teams</span>
                </li>
                <li className="py-1">
                  <span className="text-muted text-strikethrough">Insights</span>
                </li>
                <li className="py-1">
                  <span className="text-muted text-strikethrough">Single Sign-On</span>
                </li>
              </ul>
              {/*<a href="#" className="btn btn-outline-primary">
    Get Basic
  </a>*/}
            </div>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body align-items-center shadow-3d">
              <span className="badge badge-top badge-dark">Most Popular</span>
              <div className="pt-md-2">
                <h4>Business</h4>
              </div>
              <div className="d-flex align-items-start pb-md-2">
                <span className="h3">$</span>
                <span className="display-4">19</span>
              </div>
              <span className="text-small text-muted">Per user, per month</span>
              <ul className="text-center list-unstyled my-2 my-md-4">
                <li className="py-1">
                  <span>Up to 100 users</span>
                </li>
                <li className="py-1">
                  <span>Goals (OKRs)</span>
                </li>
                <li className="py-1">
                  <span>Weekly Reflections</span>
                </li>
                <li className="py-1">
                  <span>Monthly Scoring</span>
                </li>
                <li className="py-1">
                  <span>Quarterly Reviews</span>
                </li>
                <li className="py-1">
                  <span>Teams</span>
                </li>
                <li className="py-1">
                  <span>Insights</span>
                </li>
                <li className="py-1">
                  <span className="text-muted text-strikethrough">Single Sign-On</span>
                </li>
              </ul>
              {/*<a href="#" className="btn btn-primary">
    Get Pro
  </a>*/}
            </div>
          </div>
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body align-items-center ">
              <div className="pt-md-2">
                <h4>Enterprise</h4>
              </div>
              <div className="d-flex align-items-start pb-md-2">
                <span className="h3">$</span>
                <span className="display-4">29</span>
              </div>
              <span className="text-small text-muted">Per user, per month</span>
              <ul className="text-center list-unstyled my-2 my-md-4">
                <li className="py-1">
                  <span>Unlimited users</span>
                </li>
                <li className="py-1">
                  <span>Goals (OKRs)</span>
                </li>
                <li className="py-1">
                  <span>Weekly Reflections</span>
                </li>
                <li className="py-1">
                  <span>Monthly Scoring</span>
                </li>
                <li className="py-1">
                  <span>Quarterly Reviews</span>
                </li>
                <li className="py-1">
                  <span>Integrations</span>
                </li>
                <li className="py-1">
                  <span>Audience Data</span>
                </li>
                <li className="py-1">
                  <span>Premium Templates</span>
                </li>
                <li className="py-1">
                  <span>White Labelling</span>
                </li>
              </ul>
              {/*<a href="#" className="btn btn-outline-primary">
    Get Ultimate
  </a>*/}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <span>Interested in a custom plan? <a href="#">Get in touch</a>
            </span>
          </div>
        </div>
      </div>
    </section>
    {/*<section className="pt-0">
      <div className="container text-center">
        <div className="row mb-5">
          <div className="col">
            <h5>Trusted by over 30,000 of the world’s leading companies</h5>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <ul className="d-flex flex-wrap justify-content-center list-unstyled">
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/apple.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/buzzfeed.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/intercom.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/slack.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/spotify.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/target.svg'} alt="icon" data-inject-svg />
              </li>
              <li className="mx-3 mb-5">
                <img className="icon icon-md bg-primary-3 opacity-20" src={'img/logos/brand/time.svg'} alt="icon" data-inject-svg />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>*/}
    <section className="p-0">
      <div className="container mb-lg-n7 position-relative">
        <div className="row">
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;OKRs have kept me and the company on time and on track when it mattered the most.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/male-1.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>Larry Page</h6>
                  <span>Co-founder of Google</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;OKRs are not a silver bullet. They can not substitute for sound judgement, strong leadership, or a creative workplace culture. But if those fundamentals are in place OKRs can guide you to the mountaintop.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/male-4.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>John Doerr</h6>
                  <span>Investor at Kleiner Perkins</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;The OKR framework cultivate the madness and the chemistry contained inside it. It gives us an environment for risk and trust where failing is not a firing offence. When you have that sort of structured environment and the right people - magic is around the corner.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/female-4.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>Bono</h6>
                  <span>U2</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <ReactSVG
            src={'img/dividers/divider-1.svg'} 
            beforeInjection={svg => {
              svg.classList.add('bg-primary-alt')
            }}
          />
      </div>
    </section>
    <section className="bg-primary-alt">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <h3 className="h2">Frequently Asked Questions</h3>
            <div className="my-4">
              <div className="card mb-2 card-sm card-body hover-shadow-sm" data-aos="fade-up" data-aos-delay="NaN">
                <div data-target="#panel-1" className="accordion-panel-title" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="panel-1">
                  <span className="h6 mb-0">Do you have a minimum price?</span>
                  <img className="icon" src={'img/icons/interface/plus.svg'} alt="plus interface icon" data-inject-svg />
                </div>
                <div className="collapse" id="panel-1">
                  <div className="pt-3">
                    <p className="mb-0">
                      Our minimum price, or minimum annual contract value, is €4290 or $5220. This is equivalent, for example, to 25 users with our Lite Coaching Program.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-2 card-sm card-body hover-shadow-sm" data-aos="fade-up" data-aos-delay="NaN">
                <div data-target="#panel-2" className="accordion-panel-title" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="panel-2">
                  <span className="h6 mb-0">How often will I be billed?</span>
                  <img className="icon" src={'img/icons/interface/plus.svg'} alt="plus interface icon" data-inject-svg />
                </div>
                <div className="collapse" id="panel-2">
                  <div className="pt-3">
                    <p className="mb-0">
                      All our plans are billed annually. We’ll send you an invoice and activate your account after signing an Order form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-2 card-sm card-body hover-shadow-sm" data-aos="fade-up" data-aos-delay="NaN">
                <div data-target="#panel-3" className="accordion-panel-title" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="panel-3">
                  <span className="h6 mb-0">Can I try before I buy?</span>
                  <img className="icon" src={'img/icons/interface/plus.svg'} alt="plus interface icon" data-inject-svg />
                </div>
                <div className="collapse" id="panel-3">
                  <div className="pt-3">
                    <p className="mb-0">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-2 card-sm card-body hover-shadow-sm" data-aos="fade-up" data-aos-delay="NaN">
                <div data-target="#panel-4" className="accordion-panel-title" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="panel-4">
                  <span className="h6 mb-0">Do you offer bulk discounts?</span>
                  <img className="icon" src={'img/icons/interface/plus.svg'} alt="plus interface icon" data-inject-svg />
                </div>
                <div className="collapse" id="panel-4">
                  <div className="pt-3">
                    <p className="mb-0">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-2 card-sm card-body hover-shadow-sm" data-aos="fade-up" data-aos-delay="NaN">
                <div data-target="#panel-5" className="accordion-panel-title" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="panel-5">
                  <span className="h6 mb-0">Is this a subscription service?</span>
                  <img className="icon" src={'img/icons/interface/plus.svg'} alt="plus interface icon" data-inject-svg />
                </div>
                <div className="collapse" id="panel-5">
                  <div className="pt-3">
                    <p className="mb-0">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <span>Still have questions? <a href="#">Get in touch</a>
            </span>
          </div>
        </div>
      </div>
    </section>


    {/*
    <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title'>
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient'>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <h2 className='has-text-weight-semibold is-size-2'>
                  {pricing.heading}
                </h2>
                <p className='is-size-5'>{pricing.description}</p>
                <Pricing data={pricing.plans} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>*/}
  </div>
)

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

export default PricingPageTemplate
